<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: adminHeader.vue
Description:This file contains the  header of the admin landing page and Admin routes
-->
<template>
  <el-row type="flex" class="admin-header">
    <el-col :span="3" class="company-name left-control">
      <router-link to="/admin/customers">
        <er-button type="text">
          <h3>Eruvaka Technologies</h3>
        </er-button>
      </router-link>
    </el-col>
    <el-col :span="24">
      <el-row>
        <el-menu
          class="admin-menu"
          mode="horizontal"
          :default-active="activeRoute"
          :router="true"
        >
          <er-menu-item
            v-if="allowCustomerTab.includes(superAdminAccess['customers'])"
            index="customers"
            label="Customers"
            route="/admin/customers"
            :isActive="activeRoute === 'customers'"
          >
          </er-menu-item>
          <el-submenu index="2"
            v-if="allowCustomerTab.includes(superAdminAccess['devices'])">
            <template slot="title">{{
              `Devices ${getSubMenuTitle($route.meta.langKey)}`
            }}</template>
            <template v-for="(item, index) in devicesList">
              <er-menu-item
                :key="index"
                v-if="allowCustomerTab.includes(superAdminAccess[item.index])"
                :index="item.index"
                :label="item.label"
                :route="item.route"
                :isActive="activeRoute === item.index"
              >
              </er-menu-item>
            </template>
          </el-submenu>
          <er-menu-item
            v-if="allowCustomerTab.includes(superAdminAccess['clients'])"
            index="clients"
            label="Client"
            route="/admin/clients"
            :isActive="activeRoute === 'clients'"
          >
          </er-menu-item>
          <er-menu-item
            v-if="allowCustomerTab.includes(superAdminAccess['dealers'])"
            index="dealers"
            label="Dealer"
            route="/admin/dealers"
            :isActive="activeRoute === 'dealers'"
          >
          </er-menu-item>
          <er-menu-item
            v-if="allowCustomerTab.includes(superAdminAccess['account'])"
            index="account"
            label="Account Manager"
            route="/admin/account"
            :isActive="activeRoute === 'account'"
          >
          </er-menu-item>
          <er-menu-item
            v-if="allowCustomerTab.includes(superAdminAccess['activity-log'])"
            index="activity_log"
            label="Activity Log"
            route="/admin/activity-log"
            :isActive="activeRoute === 'activity-log'"
          >
          </er-menu-item>
          <!-- <er-menu-item
            index="new_release"
            label="New Release"
            route="/admin/new-release"
            :isActive="activeRoute === 'new-release'"
            >
            </er-menu-item> -->
          <er-menu-item
            index="help"
            label="Help"
            route="/admin/help"
            :isActive="activeRoute === 'help'"
          >
          </er-menu-item>
          <er-menu-item
            v-if="allowCustomerTab.includes(superAdminAccess['inquiry-log'])"
            index="inquiry_log"
            label="Inquiry Log"
            route="/admin/inquiry-log"
            :isActive="activeRoute === 'inquiry-log'"
          >
          </er-menu-item>
          <er-menu-item
            v-if="allowCustomerTab.includes(superAdminAccess['subscriptions'])"
            index="subscriptions"
            label="Subscriptions"
            route="/admin/subscriptions/customer-wise"
            :isActive="activeRoute === 'subscriptions'"
          >
          </er-menu-item>
          <er-menu-item
            v-if="allowCustomerTab.includes(superAdminAccess['users'])"
            index="users"
            label="Users"
            route="/admin/users"
            :isActive="activeRoute === 'users'"
          >
          </er-menu-item>
        </el-menu>
      </el-row>
    </el-col>
    <el-col class="right-control" :span="7">
      <center>
        <span>
          <span class="header-right-user-name truncate">
            <a href="/admin/profile">{{ getUserName }}</a>
          </span>
          <el-divider
            class="user-logout-divider"
            direction="vertical"
          ></el-divider>
          <er-button class="logout-button" @click="logout" type="text">
            <el-row type="flex" :gutter="5">
              <el-col>{{ $t("Comn_logout") }}</el-col>
              <el-col>
                <i class="el-icon-logout">
                  <img class="logout-icon" src="@/assets/logout.svg" />
                </i>
              </el-col>
            </el-row>
          </er-button>
        </span>
      </center>
    </el-col>
  </el-row>
</template>

<script>
import { mapActions } from "vuex";
import { superAdminAccess } from "@/constants/uiStates";

export default {
  computed: {
    getUserName() {
      return (
        this.$store.getters["superadmin/getSuperAdminProfile"] &&
        this.$store.getters["superadmin/getSuperAdminProfile"].first_name +
          " " +
          this.$store.getters["superadmin/getSuperAdminProfile"].last_name
      );
    },
    superAdminAccess() {
      return superAdminAccess;
    },
    activeRoute() {
      return this.$route.path.split("/")[2];
    },
    devicesList() {
      const deviceRouteObj = (route, label, index) => {
        return {
          index,
          label,
          route
        };
      };
      return [
        deviceRouteObj(
          "/admin/gateways?tab=gatewayDetails",
          "Gateway",
          "gateways"
        ),
        deviceRouteObj(
          "/admin/pondmothers?tab=pondMotherDetails",
          "PondMother",
          "pondmothers"
        ),
        deviceRouteObj(
          "/admin/pondguards?tab=pondGuardDeviceDetails",
          "PondGuard",
          "pondguards"
        ),
        deviceRouteObj(
          "/admin/shrimptalks?tab=shrimpTalkDetails",
          "ShrimpTalk",
          "shrimptalks"
        ),
        deviceRouteObj("/admin/feedblowers", "FeedBlower", "feedblowers"),
        deviceRouteObj("/admin/shrimpsnaps", "ShrimpSnap", "shrimpsnaps")
      ];
    },
    allowCustomerTab() {
      return this.$store.getters["superadmin/getSuperAdminProfile"]?.permissions || [];
    }

  },
  methods: {
    ...mapActions("superadmin", {
      adminNotifyBackendOnLogout: "adminNotifyBackendOnLogout"
    }),
    async logout() {
      await this.$store.dispatch("auth/superAdminSignOut");
      try {
        await this.adminNotifyBackendOnLogout();
      } catch (err) {
        console.log("error notifying on admin logout");
      }
      this.$router.push("/");
    },
    getSubMenuTitle(langKey) {
      const subMenuItems = [
        "Comn_gateway",
        "Comn_feed_blower",
        "Comn_pond_mother",
        "Comn_pond_guard",
        "Comn_shrimptalk",
        "shrimpsnap.device_name"
      ];
      if (subMenuItems.includes(langKey)) {
        return `(${this.$tc(langKey, 1)})`;
      }
      return "";
    }
  }
};
</script>

<style lang="scss">
.admin-header {
  .company-name {
    line-height: 2.3;
  }
  .right-control,
  .left-control {
    border-bottom: 1px solid #e6e6e6;
  }
  .admin-menu {
    &.el-menu--horizontal > .el-submenu .el-submenu__title {
      height: calc(var(--header-height) - 2px) !important;
      line-height: calc(var(--header-height) - 2px) !important;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
    }
    .el-submenu__title {
      color: #0a2463;
      opacity: 0.8;
    }
  }
  .header-right-user-name {
    font-size: $app_font_size_1;
    max-width: 200px;
    a {
      text-transform: capitalize;
      text-decoration: none;
    }
  }
}
</style>
