<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: dealerHeader.vue
Description: This file is the part of the dealer site. It is headercomponent of the dealer site.
-->
<template>
  <div class="dealer-header">
    <div style="display: flex; flex-direction: row">
      <div style="display: flex; align-items: center; margin-right: 20px">
        <pondlogs-logo class="menu-icon" color="#0A2463"></pondlogs-logo>
        &nbsp; &nbsp;
        <span class="nav__header-title">PondLogs</span>
      </div>
    </div>
    <div>
      <el-menu
        class="dealer-menu"
        mode="horizontal"
        :default-active="activeRoute"
        :router="true"
      >
        <er-menu-item
          index="users"
          route="/dealer/users"
          :isActive="activeRoute === 'users'"
          >{{ $t("DL_customers") }}</er-menu-item
        >
        <er-menu-item
          index="pondmothers"
          route="/dealer/pondmothers"
          :isActive="activeRoute === 'pondmothers'"
          >{{ $t("Comn_pond_mother") }}</er-menu-item
        >
        <er-menu-item
          index="pondguards"
          route="/dealer/pondguards"
          :isActive="activeRoute === 'pondguards'"
          >{{ $t("Comn_pond_guard") }}</er-menu-item
        >
        <er-menu-item
          index="shrimptalks"
          route="/dealer/shrimptalks"
          :isActive="activeRoute === 'shrimptalks'"
          >{{ $t("Comn_shrimptalk") }}</er-menu-item
        >
        <er-menu-item
          index="gateways"
          route="/dealer/gateways"
          :isActive="activeRoute === 'gateways'"
          >{{ $t("Comn_gateway") }}</er-menu-item
        >
      </el-menu>
    </div>
    <div style="display: flex; align-items: center; justify-content: center">
      <span>
        <er-dropdown
          @item-click="handleChangeInLanguage"
          trigger="click"
          :listOfItems="langs"
        >
          <template slot="prefix">
            <i class="el-icon-lang">
              <img
                src="../../assets/home/lang-icon.svg"
                height="15"
                width="20,"
              />
            </i>
          </template>
          <template slot="title">
            <p>{{ langSelected }}</p>
          </template>
        </er-dropdown>
      </span>
      &nbsp;
      <span>
        <!-- <a href="/dealer/profile"></a> -->
        <span class="header-user-name">
          {{ ftm__formatEmpty(getDealerUsername.first_name) }}
        </span>
        <el-divider
          class="user-logout-divider"
          direction="vertical"
        ></el-divider>
        <er-button class="logout-button" @click="logout" type="text">
          <el-row type="flex" :gutter="5">
            <el-col>{{ $t("Comn_logout") }}</el-col>
            <el-col>
              <i class="el-icon-logout">
                <img class="logout-icon" src="@/assets/logout.svg" />
              </i>
            </el-col>
          </el-row>
        </er-button>
      </span>
    </div>
  </div>
</template>

<script>
import pondlogsLogo from "@/components/index/pondlogsLogo";
import filtersMixin from "@/mixins/filtersMixin";
import { mapActions } from "vuex";
export default {
  mixins: [filtersMixin],
  components: {
    pondlogsLogo
  },
  data: function() {
    return {
      langs: { Español: "es", English: "en", Chinese: "zh", Portuguese: "pt" },
      revLangs: { es: "Español", en: "English", zh: "Chinese", pt: "Portuguese" }
    };
  },
  computed: {
    getDealerUsername() {
      return this.$store.getters["dealer/getDealerProfile"];
    },
    langSelected() {
      return this.revLangs[this.$i18n.locale];
    },
    activeRoute() {
      return this.$route.path.split("/")[2];
    }
  },
  methods: {
    ...mapActions("dealer", {
      dealerNotifyBackendOnLogout: "dealerNotifyBackendOnLogout"
    }),
    handleChangeInLanguage(value) {
      this.$i18n.locale = value;
      const indexPath = this.$route.meta.langKey;
      const appTitle = document.getElementById("appTitle");
      appTitle.innerHTML = `${this.$t(indexPath)} | PondLogs`;
      localStorage.setItem(`lang`, value);
    },

    async logout() {
      await this.$store.dispatch("auth/dealerSignOut");
      try {
        await this.dealerNotifyBackendOnLogout();
      } catch (err) {
        console.log("error notifying on dealer logout");
      }
      this.$router.push("/");
    },
    async initComponent() {
      try {
        this.loading = true;
        await this.$store.dispatch("dealer/fetchUserProfile");
      } catch (err) {
        console.log(err);
        // this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    await this.initComponent();
  }
};
</script>

<style lang="scss">
.dealer-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .nav__header-title {
    @include responsiveProperty(
      font-size,
      $app_font_size_3,
      $app_font_size_xl,
      $app_font_size_xl
    );
    color: #1f2041;
    text-decoration: none;
    font-weight: 500;
  }

  .el-button.logout-button {
    height: 100%;
  }
  .header-user-name {
    max-width: 200px;
    color: #606266;
    text-transform: capitalize;
    text-decoration: none;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }

  .dealer-menu {
    &.el-menu--horizontal > .er-menu__link > .er-menu-item {
      height: calc(var(--header-height) - 4px) !important;
      line-height: calc(var(--header-height) - 4px) !important;
    }
  }
  .header-right-user-name {
    max-width: 200px;
    text-transform: capitalize;
    text-decoration: none;
    font-size: 14px;
  }
  .er-dropdown {
    color: #909399;
  }
  .er-dropdown .er-dropdown-trigger__suffix .er-icon-caret {
    color: #909399;
  }

  .er-dropdown .er-dropdown-trigger__suffix {
    margin-left: 10px;
    margin-top: auto;
  }
  .dealer-header .er-dropdown {
    color: #606266;
  }
}
</style>
