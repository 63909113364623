/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: accountManagerRouters.js
Description: This file contains the router configuration useful for handling the navigation for super admin site.
*/
import indexDealer from "@/views/indexDealer";
import view404 from "@/views/view404";
import viewNoInternet from "@/views/viewNoInternet";

export const Dealer = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/dealer/CustomerDetailsTable"
  );

export const Profile = () =>
  import(/* webpackChunkName: "adminPages" */ "@/components/dealer/Profile");
export const PondMothers = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/dealer/PondMotherTab"
  );
export const PondGuards = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/dealer/PondGuardTab"
  );
export const ShrimpTalks = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/dealer/shrimpTalkTab/index.vue"
  );
export const Gateways = () =>
  import(/* webpackChunkName: "adminPages" */ "@/components/dealer/GatewayTab");

export const Subscriptions = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/dealer/subscriptionTab/index.vue"
  );

export const SubscriptionsManage = () =>
  import("@/components/dealer/subscriptionTab/subscritptionManager.vue");

export const SubscriptionsManageCustomer = () =>
  import(
    "@/components/dealer/subscriptionTab/subscritptionManagerCustomer.vue"
  );

export const CustomerPondSummary = () =>
  import("@/components/dealer/subscriptionTab/customerPondSummary.vue");

export const PondDetails = () =>
  import("@/components/dealer/subscriptionTab/pondDetails.vue");

export const SubscriptionsYearwise = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/dealer/subscriptionTab/yearWiseCustomer.vue"
  );

const doesUserCanAccess = function(to, from, next) {
  return next();
};

export default {
  path: "/dealer",
  component: indexDealer,
  children: [
    {
      path: "/",
      redirect: "users"
    },
    {
      path: "users",
      name: "users",
      component: Dealer,
      meta: {
        langKey: "DL_customers"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "profile",
      name: "profile",
      component: Profile,
      meta: {
        langKey: "Comn_devices"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "pondmothers",
      name: "pondmothers",
      component: PondMothers,
      meta: {
        langKey: "Comn_pond_mother"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "pondguards",
      name: "pondguards",
      component: PondGuards,
      meta: {
        langKey: "Comn_pond_guard"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "shrimptalks",
      name: "shrimptalks",
      component: ShrimpTalks,
      meta: {
        langKey: "Comn_shrimptalk"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "gateways",
      name: "gateways",
      component: Gateways,
      meta: {
        langKey: "Comn_gateway"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "subscriptions",
      name: "Subscriptions",
      component: Subscriptions,
      meta: {
        langKey: "dealer.subscriptions"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "subscriptions/manage",
      name: "Subscriptions-manage",
      component: SubscriptionsManage,
      meta: {
        langKey: "dealer.subscriptions"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "subscriptions/manage-customer",
      name: "Subscriptions-manage-customer",
      component: SubscriptionsManageCustomer,
      meta: {
        langKey: "dealer.subscriptions"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "subscriptions/year-wise",
      name: "Subscriptions-year-wise",
      component: SubscriptionsYearwise,
      meta: {
        langKey: "dealer.subscriptions"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "subscriptions/customer-wise",
      name: "Subscriptions-customer",
      component: CustomerPondSummary,
      meta: {
        langKey: "dealer.subscriptions"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "subscriptions/pond-details",
      name: "Subscriptions-pond-details",
      component: PondDetails,
      meta: {
        langKey: "dealer.subscriptions"
      },
      beforeEnter: doesUserCanAccess
    },

    {
      path: "404",
      name: "admin404",
      component: view404,
      meta: {
        langKey: "PondLogs"
      }
    },
    {
      path: "nointernet",
      name: "nointernet",
      component: viewNoInternet,
      meta: {
        langKey: "PondLogs"
      }
    }
  ]
};
