/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pmSchedule.js
Description: This file has the model,functions  of pmSchedule object used in the pondlogs customer site
*/
import PondMother from "@/model/pondmother";
import { generateColorForMode } from "@/utils/componentColors";
import ShrimpTalk from "./shrimptalk";
class Pond {
  constructor() {
    this._id = "";
    this.assigned_schedule_id = null;
    this.center = null;
    this.created_at = "";
    this.cultivations = [];
    this.cultivation_date = undefined;
    this.feed_template_assigned = "";
    this.feed_type_id = "";
    this.feed_blowers = [];
    this.geometry = { type: "", coordinates: [] };
    this.hasSchedule = false;
    this.hasTemplate = false;
    this.location_id = "";
    this.mode = [];
    this.pond_coordinates = [];
    this.pond_guards = [];
    this.pond_id = "";
    this.pond_mothers = [];
    this.post_larva_stocked = 0;
    this.managed_by = "MANUAL";
    this.shrimp_talks = [];
    this.initial_abw = 0.1;
    this.size = "0";
    this.status = "INACTIVE";
    this.temperature = 0;
    this.timezone = { offSet: 0, name: "" };
    this.title = "Pond";
    this.units = "ha";
    this.updated_at = "";
    this.user_id = "";
  }

  castToPondObj(inputPondDetails) {
    if (typeof inputPondDetails === "string") {
      this._id = inputPondDetails;
    } else {
      Object.keys(inputPondDetails).forEach(key => {
        if (key === "shrimp_talks") {
          this[key] = inputPondDetails[key].map(st => {
            const stObj = new ShrimpTalk();
            stObj.castToSTObj(st);
            return stObj;
          });
          return;
        }
        if (key === "pond_mothers") {
          // console.log(this[key])
          this[key] = inputPondDetails[key].map(pm => {
            const pmObj = new PondMother();
            pmObj.castToPMObj(pm);
            return pmObj;
          });
          return;
        }
        if (key === "units") {
          this[key] =
            inputPondDetails[key] === "hc" ? "ha" : inputPondDetails[key];
        } else {
          this[key] = inputPondDetails[key];
        }
      });
      this.pond_id = inputPondDetails._id;
      this.pond_coordinates = this.geometry.coordinates;
    }
  }

  getPondPropertiesObj() {
    const propertyNameList = [
      "title",
      "pond_id",
      "units",
      "location",
      "shrimp_talks",
      "pond_guards",
      "pond_mothers",
      "center"
    ];
    const properties = {};
    propertyNameList.forEach(property => {
      properties[property] = this[property];
    });
    return properties;
  }

  static generateColorForMode(mode) {
    return generateColorForMode(mode);
  }
}

export default Pond;
